@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-slate-900 bg-slate-50;
}

/* Custom Range Slider */
/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 15rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #e2e8f0;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: white;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 1.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.3), 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid #2563eb;
  outline: 3px solid #2563eb;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #e2e8f0;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 1.25rem; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: white;
  height: 1.25rem;
  width: 1.25rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #2563eb;
  outline: 3px solid #2563eb;
  outline-offset: 0.125rem;
}
